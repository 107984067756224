<template>
  <base-section id="about-our-product">
    <base-section-heading
      color="grey lighten-2"
      icon="mdi-vuetify"
      title="Información de Interés"
    />

    <v-container>
      <v-row>
        <v-col
          key="Grow your Revenue"
          cols="12"
          md="4"
        >
          <base-info-card
            subtitle="PARA DOCENTES"
            text="Acceso exclusivo a carpeta GOOGLE DRIVE para información de interés DOCENTES."
            callout="01"
          />
          <base-btn
            color="blue"
            href="https://drive.google.com/drive/u/1/folders/1pKCAWBRuQal-lCSnNu5Pls1CMa8JGc6i"
            large
            outlined
            target="_blank"
          >
            Ir
          </base-btn>
        </v-col>
        <v-col
          key="Grow your Revenue"
          cols="12"
          md="4"
        >
          <base-info-card
            subtitle="ORIENTACIÓN ESCOLAR"
            text="Atención a estudiantes, padres de familia, docente, talleres, escuela de padres, orientación vocacional..."
            callout="02"
          />
          <base-btn
            color="blue"
            href="https://drive.google.com/drive/folders/1kX9K4fVOSC8qo4KFyhMGx40qmQf-WrDT?usp=sharing"
            large
            outlined
            target="_blank"
          >
            Ir
          </base-btn>
        </v-col>
        <v-col
          key="Grow your Revenue"
          cols="12"
          md="4"
        >
          <base-info-card
            subtitle="PACTO DE CONVIVENCIA "
            text="INFORMACIÓN PACTO DE CONVIVENCIA."
            callout="03"
          />
          <base-btn
            color="blue"
            href="https://drive.google.com/drive/folders/1wPj3UdTd3MRqVExPbRjd2UszOZGVK4-u?usp=sharing"
            large
            outlined
            target="_blank"
          >
            Ir
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionDirectivaInformacion',
  }
</script>
